import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  data: any;
  url = environment.apiBase;

  showLoader = true;

  constructor(private http: HttpClient) { }

  getRecentTransactionSearch(body): Observable<any> {
    /*
     * Construct query params for advanced searching. 
     * Make sure numeric zero (0) is not skipped.
     */
    const params: any = {
      pageSize: body.pageSize,
      page: body.page
    };

    // transactionId is numeric; we allow "0" to pass if user typed 0
    if (body.transactionId !== null && body.transactionId !== undefined) {
      params.transactionId = body.transactionId;
    }
    // drugNdc is string but might be empty
    if (body.drugNdc) {
      params.drugNdc = body.drugNdc;
    }
    // drugName
    if (body.drugName) {
      params.drugName = body.drugName;
    }
    // updatedPrice is numeric
    if (body.updatedPrice !== null && body.updatedPrice !== undefined) {
      params.updatedPrice = body.updatedPrice;
    }
    // daysSupply is numeric
    if (body.daysSupply !== null && body.daysSupply !== undefined) {
      params.daysSupply = body.daysSupply;
    }
    // cardHolderName
    if (body.cardHolderName) {
      params.cardHolderName = body.cardHolderName;
    }
    // claimIndicator
    if (body.claimIndicator) {
      params.claimIndicator = body.claimIndicator;
    }
    // claimStatus
    if (body.claimStatus) {
      params.claimStatus = body.claimStatus;
    }
    // fromDate
    if (body.fromDate) {
      params.fromDate = body.fromDate;
    }
    // toDate
    if (body.toDate) {
      params.toDate = body.toDate;
    }

    // Make the GET call with the entire query string
    return this.http.get(`${this.url}api/Claims/GetRecentTransactionSearch`, { params });
  }

  getReversalSearch(body): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Claims/GetReversalSearch?ClaimNumber=' +
          body.claimNumber +
          '&CardholderNumber=' +
          body.cardholderNumber +
          '&AuthorizationNumber=' +
          body.authorizationNumber +
          '&MemberFirstName=' +
          body.firstName +
          '&MemberLastName=' +
          body.lastName
      );
  }

  getAWPSearch(body): Observable<any> {
    return this.http
      .get(
        this.url +
          'api/Claims/GetAWPSearch?NDCNumber=' +
          body.nDCNumber +
          '&GPI=' +
          body.gPI +
          '&pagesize=' +
            body.pageSize +
            '&page=' + body.page
      );
  }

  getLoader(): any {
    return this.showLoader;
  }

  setLoader(value) {
    this.showLoader = value;
  }
}
