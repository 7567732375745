import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TransactionService = /** @class */ (function () {
    function TransactionService(http) {
        this.http = http;
        this.url = environment.apiBase;
        this.showLoader = true;
    }
    TransactionService.prototype.getRecentTransactionSearch = function (body) {
        /*
         * Construct query params for advanced searching.
         * Make sure numeric zero (0) is not skipped.
         */
        var params = {
            pageSize: body.pageSize,
            page: body.page
        };
        // transactionId is numeric; we allow "0" to pass if user typed 0
        if (body.transactionId !== null && body.transactionId !== undefined) {
            params.transactionId = body.transactionId;
        }
        // drugNdc is string but might be empty
        if (body.drugNdc) {
            params.drugNdc = body.drugNdc;
        }
        // drugName
        if (body.drugName) {
            params.drugName = body.drugName;
        }
        // updatedPrice is numeric
        if (body.updatedPrice !== null && body.updatedPrice !== undefined) {
            params.updatedPrice = body.updatedPrice;
        }
        // daysSupply is numeric
        if (body.daysSupply !== null && body.daysSupply !== undefined) {
            params.daysSupply = body.daysSupply;
        }
        // cardHolderName
        if (body.cardHolderName) {
            params.cardHolderName = body.cardHolderName;
        }
        // claimIndicator
        if (body.claimIndicator) {
            params.claimIndicator = body.claimIndicator;
        }
        // claimStatus
        if (body.claimStatus) {
            params.claimStatus = body.claimStatus;
        }
        // fromDate
        if (body.fromDate) {
            params.fromDate = body.fromDate;
        }
        // toDate
        if (body.toDate) {
            params.toDate = body.toDate;
        }
        // Make the GET call with the entire query string
        return this.http.get(this.url + "api/Claims/GetRecentTransactionSearch", { params: params });
    };
    TransactionService.prototype.getReversalSearch = function (body) {
        return this.http
            .get(this.url +
            'api/Claims/GetReversalSearch?ClaimNumber=' +
            body.claimNumber +
            '&CardholderNumber=' +
            body.cardholderNumber +
            '&AuthorizationNumber=' +
            body.authorizationNumber +
            '&MemberFirstName=' +
            body.firstName +
            '&MemberLastName=' +
            body.lastName);
    };
    TransactionService.prototype.getAWPSearch = function (body) {
        return this.http
            .get(this.url +
            'api/Claims/GetAWPSearch?NDCNumber=' +
            body.nDCNumber +
            '&GPI=' +
            body.gPI +
            '&pagesize=' +
            body.pageSize +
            '&page=' + body.page);
    };
    TransactionService.prototype.getLoader = function () {
        return this.showLoader;
    };
    TransactionService.prototype.setLoader = function (value) {
        this.showLoader = value;
    };
    TransactionService.ngInjectableDef = i0.defineInjectable({ factory: function TransactionService_Factory() { return new TransactionService(i0.inject(i1.HttpClient)); }, token: TransactionService, providedIn: "root" });
    return TransactionService;
}());
export { TransactionService };
